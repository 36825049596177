input{background:0 0;border:none;border-bottom:3px solid #fff;border-radius:0;color:#fff;font-size:40px}
select{background: none; background-color: transparent; border: none; color: white; font-size: 20px}
select:hover{cursor: pointer;}
select:focus{border: none; outline: none}
option{color: black;}
.formContainer{display:flex;}
.selectContainer{display:inline-flex; flex-direction: column;}
.perkContainer{display:flex;flex-wrap:wrap}
.perkContainer img{transition:filter .35s}
.perkContainer img:hover{cursor:pointer;transition:filter .35s;filter:invert()}
.perkFlex{display:flex}
.perkFlex>div:first-child{flex-basis:65%}
.toolTip{flex-basis: 34%; position: sticky}
.flexColumn{display:flex;flex-basis:35%;flex-direction:column;top:0;position:sticky; height: fit-content}
.selectedPerks{display: flex; flex-wrap: wrap;}
.selectedPerks > *{flex-basis: 20%; display: flex; flex-direction: column; justify-content: center; align-items: center;}
.selectedPerks span{text-align: center;}
.selectedPerkIcon:hover{cursor: pointer;}
.lds-dual-ring{display:flex; width: 100%; justify-content: center; height:80px}
.lds-dual-ring:after{content:" ";display:block;width:64px;height:64px;margin:8px;border-radius:50%;border:6px solid #fff;border-color:#fff transparent #fff transparent;animation:lds-dual-ring 1.2s linear infinite}
@keyframes lds-dual-ring{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}