.techList h5{margin-bottom: 0; padding-bottom: 0;}
.techList ul{margin: 1vh 0 0 5vw}

.splashText{display:grid; grid-template-columns: 100%; justify-items:center;}
.splashText > *:first-child{color:#fe7a89; font-size: 5em; grid-row:1; grid-column:1; text-align: center;}
.splashText > *:last-child{color:#ffff4b; grid-row: 1; grid-column:1; margin-top:1.25em}
.splashText > *{margin: 0}

.heroText{
    /* background: linear-gradient(305deg, red, blue); */
    background: linear-gradient(360deg, #ffff51, #fd6ffe);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subHeader{text-align: center;}

.sun {
margin: 0 auto;
border-radius:50%;
height:25vh;
width:25vw;
background: linear-gradient(180deg, #ffff51, #fd6ffe);
box-shadow: inset 0 0 20px #ffff51, 0px -5px 20px #ffff51, 0 20px 200px #fd6ffe, 0 0 80px #fd6ffe, inset 0px -20px 20px #fd6ffe;
z-index:12;
z-index:-99;
}