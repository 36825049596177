.photoGalleryHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.photoGallery{
    display: flex;
    flex-direction: column;
}

.photoGallery figure{
    margin: 0;
}

.photoGallery img{
    width: 100%;
}