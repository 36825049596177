form{display:flex;}
  
.masteryCardContainer{margin: 1em 0; display:grid; grid-template-columns: repeat(3, minmax(100px, 33%)); grid-gap: 1em;}
.masteryCard{background-color: #2a303b; display:flex; flex-direction: column; justify-content: flex-start; text-align: left; border-radius: 5px;}
.masteryCard img{width: 100%;} 
.masteryCard div{padding: 0 1em;}

/* ----------------------------------------------
* Generated by Animista on 2022-3-13 14:37:24
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */
.container > svg{margin-top:1em;}
@keyframes App-logo-spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}
.rotate-in-center{-webkit-animation:rotate-in-center 1s cubic-bezier(.25,.46,.45,.94) infinite both;animation:rotate-in-center 1s cubic-bezier(.25,.46,.45,.94) infinite both}
@-webkit-keyframes rotate-in-center{0%{-webkit-transform:rotate(-360deg);transform:rotate(-360deg);opacity:0}100%{-webkit-transform:rotate(0);transform:rotate(0);opacity:1}}@keyframes rotate-in-center{0%{-webkit-transform:rotate(-360deg);transform:rotate(-360deg);opacity:0}100%{-webkit-transform:rotate(0);transform:rotate(0);opacity:1}}


@media screen and (max-width: 800px){
.masteryCardContainer{grid-template-columns: repeat(1, 1fr);}
.masteryCard{margin-top:1em;}
}