
body{
  /* background-color: #080114; */
  background: linear-gradient(#1c114a, #ea2371);
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
  font-size: calc(10px + 2vmin);
  font-family: body;
}

@font-face {
  font-family: synth;
  src: url(../fonts/Streamster.ttf);
}

@font-face {
  font-family: aliens;
  src: url(../fonts/SFAlienEncountersSolid-Ital.ttf);
}

@font-face {
  font-family: goodTimes;
  src: url(../fonts/good\ times\ rg.otf);
}

@font-face {
  font-family: body;
  src: url(../fonts/FiraSans-Regular.ttf);
}


.synth{
  font-family: synth;
}

.alien{
  font-family: aliens;
}

.App > div{
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

a, .emailLink{color:#f222ff; text-decoration: none; text-decoration: underline 0.15em rgba(0, 0, 0, 0); transition: text-decoration-color 300ms;}
a:hover, .emailLink:hover {text-decoration-color: #f222ff; cursor: pointer;}

