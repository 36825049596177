.container{display: flex; flex-direction: column; align-items: center;}
.gridContainer{display:flex; flex-direction: column;}
.gridContainer > div{display: grid;grid-template-columns: repeat(5, 75px); height:75px}
.gridContainer > div > div, .guessGuide div{transition: background .5s; border:1px solid white; border-radius: 0; color: white; font-weight: bold; display: flex; align-items: center; justify-content: center;}
.gridContainer div.wrong, .guessGuide div.wrong, .keyboardContainer button.wrong{background: gray;}
.gridContainer div.partial, .guessGuide div.partial, .keyboardContainer button.partial{background: orange;}
.gridContainer div.correct, .guessGuide div.correct, .keyboardContainer button.correct{background: green;}
.allie{ display: none; position: absolute; z-index: 100; background-color: #e72371; width: 50vw; border-radius: 10px; padding: 1em}
.allie button{display:flex; margin: auto; border-radius: 5px; font-size: .75em}
.guessGuide {margin-top: 10px; display: flex; flex-direction: column; gap:10px}
.guessGuide span{display: flex; align-items: center;}
.guessGuide div{margin-right: 10px; width: 50px; height: 50px}
.keyboardContainer{margin-left: 0; margin-top: 10px; display: flex; flex-direction: column; align-items: center; gap:5px}
.keyboardContainer span{display: flex; gap: 1%}
.keyboardContainer button{background: white; border-radius: 3px; font-size: .65em; min-height: 40px;}
.keyboardContainer button:hover, .keyboardContainer button:focus{color:black;}
.victoryText{display: flex; flex-direction: column; align-items: center;}
button.victoryButton{border-radius: 5px;}